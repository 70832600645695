import { cloneDeep, isArraysEqual } from 'HELPERS'

const isObject = obj =>
  Object.prototype.toString.call(obj) === '[object Object]'
// TODO: upd with object args
export const compareSource = (
  source,
  initialSource,
  excludeKeys = [],
  possibleDifferntValues,
  excludeNullish
) => {
  if (!source && !initialSource) return false

  const cloneSource = cloneDeep(source)

  for (const key in cloneSource) {
    if (!excludeKeys.includes(key)) {
      const sourceItem = cloneSource[key]
      const cloneSourceItem = initialSource?.[key]

      if (excludeNullish && sourceItem === null) {
        delete cloneSource[key]
      } else if (
        possibleDifferntValues &&
        (possibleDifferntValues.includes(sourceItem) ||
          (Array.isArray(sourceItem) &&
            possibleDifferntValues.includes(sourceItem[0])))
      ) {
        delete cloneSource[key]
      } else if (Array.isArray(sourceItem)) {
        // If key value is array
        const isEqual = isArraysEqual(sourceItem, cloneSourceItem)

        if (isEqual) {
          delete cloneSource[key]
        } else {
          cloneSource[key] = sourceItem
        }
      } else if (isObject(sourceItem)) {
        // If key value is object
        cloneSource[key] = compareSource(sourceItem, cloneSourceItem)

        if (!Object.keys(cloneSource[key]).length) {
          delete cloneSource[key]
        }
      } else {
        if (sourceItem === cloneSourceItem) {
          delete cloneSource[key]
        }
      }
    } else {
      delete cloneSource[key]
    }
  }

  return cloneSource
}
