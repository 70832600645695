export const structure = {
  map: {},
  tree: [],
  fields: {},
  layerNames: {},
  layerTypes: {},
  selectedModelId: null,
  activeLayers: [],
  objectsCount: {},
  legendWidgetsOnMap: {}
}

export const nonClearableFields = [
  'city',
  'map',
  'activeLayers',
  'legendWidgetsOnMap'
]
