export const MODULE = 'asudd'
export const MAIN_ENDPOINT = 'asudd_v2'

export const TRAFFIC_LIGHTS = 'traffic-lights'
export const T7_CONTROLLERS = 't7_controllers'
export const DETECTORS = 'detectors'

export const dataTypes = {
  TRAFFIC_LIGHTS,
  T7_CONTROLLERS,
  DETECTORS
}

// Tabs directions
export const DIRECTIONS = {
  forward: 'forward',
  backward: 'backward'
}

// New group traffic lights
export const NEW_GROUP = 'new-group'

// MAP LAYERS
// Traffic lights
export const HELP_LAYER = 'help_layer'
export const SOURCE_TRAFFIC_LIGHTS = TRAFFIC_LIGHTS
export const SOURCE_TRAFFIC_LIGHTS_ACTIVE = `${SOURCE_TRAFFIC_LIGHTS}_active`
export const LAYER_TRAFFIC_LIGHTS = SOURCE_TRAFFIC_LIGHTS
export const LAYER_TRAFFIC_LIGHTS_CIRCLE = `${SOURCE_TRAFFIC_LIGHTS}_circle`
export const LAYER_TRAFFIC_LIGHTS_ACTIVE = SOURCE_TRAFFIC_LIGHTS_ACTIVE

// Detectors
export const SOURCE_DETECTORS = DETECTORS
export const LAYER_DETECTORS = SOURCE_DETECTORS
export const LAYER_DETECTORS_CIRCLE = `${SOURCE_DETECTORS}_circle`
export const LAYER_DETECTORS_ACTIVE_NODE = `${SOURCE_DETECTORS}_active_node`

// Traffic lights T7
export const SOURCE_TRAFFIC_LIGHTS_T7 = T7_CONTROLLERS
export const SOURCE_TRAFFIC_LIGHTS_T7_ACTIVE = `${T7_CONTROLLERS}_active`
export const LAYER_TRAFFIC_LIGHTS_T7 = SOURCE_TRAFFIC_LIGHTS_T7
// eslint-disable-next-line vue/max-len
export const LAYER_TRAFFIC_LIGHTS_T7_CIRCLE = `${SOURCE_TRAFFIC_LIGHTS_T7}_circle`
export const LAYER_TRAFFIC_LIGHTS_T7_ACTIVE = SOURCE_TRAFFIC_LIGHTS_T7

// Greenwave
export const GW_LINE = 'gw-line'
export const GW_LINE_ARROWS = 'gw-line-arrows'
export const GW_CAPTION = 'gw-caption'

// Crosswalks
export const CROSSWALKS = 'crosswalks'
