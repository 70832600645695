<template>
  <div class="r-search-input">
    <r-block
      col
      no-padding
      border="bottom"
      :class="['r-search-input__wrapper', { 'with-attrs': attributesList }]"
    >
      <!-- TODO: check this component -->
      <r-select
        v-if="attributesList"
        v-model="attribute"
        :width="attrWidth"
        :options="attributesList"
        dropdown-style
      />
      <r-select
        v-if="inputValues"
        v-model="selectValue"
        class="r-search-input__input-select"
        :options="inputValues"
        multiple
        filterable
        dropdown-style
      />
      <r-input
        v-else
        ref="input"
        v-model="value"
        class="r-input"
        :placeholder="placeholderValue"
        :clearable="clearable"
        material
        icon-name="search"
        :loading="loading"
        @blur="handleBlur"
        @focus="handleFocus"
        @clear-all="handleClearAll"
      />
    </r-block>
    <r-button
      v-if="resetButton"
      simple
      icon="replay"
      @click="$emit('reset', { active: false, id: attribute, prop: null })"
    />
    <r-button
      v-if="searchButton || selectValue.length"
      @click="search"
    >
      {{ $t('search') }}
    </r-button>
  </div>
</template>

<script>
import { debounce } from 'HELPERS'

export default {
  props: {
    filterText: {
      type: String,
      default: ''
    },
    attributesList: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    searchButton: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    attrWidth: {
      type: Number,
      default: 150
    },
    resetButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.filterText,
      selectValue: null,
      oldValue: this.filterText,
      attribute: this.attributesList?.[0].id || null,
      inputValues: this.attributesList?.[0].values || null
    }
  },
  computed: {
    placeholderValue() {
      return this.placeholder || this.$t('search')
    }
  },
  watch: {
    value(val) {
      this.updateInput(val)
    },
    filterText(text) {
      this.updateValue(text)
    },
    attribute(val) {
      this.value = ''
      this.inputValues =
        this.attributesList.find(item => item.id === val).values || null
      this.selectValue = []
    }
  },
  created() {
    this.selectValue = []
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.enterListener)
  },
  methods: {
    updateInput: debounce(function (val) {
      if (this.oldValue?.trim() === val?.trim()) return

      const newValue = val?.replace(/\s+/g, ' ')

      this.$emit('change', newValue)
      this.oldValue = newValue

      if (this.value !== newValue) {
        this.value = newValue
      }
    }, 256),
    updateValue(val) {
      if (this.oldValue !== val) {
        this.value = val
      }
    },
    handleEvent(event) {
      this.$emit('handle-event', event)
    },
    handleFocus() {
      this.handleEvent('focus')
      // TODO: replace for input event
      document.addEventListener('keydown', this.enterListener)
    },
    handleBlur() {
      this.handleEvent('blur')
      document.removeEventListener('keydown', this.enterListener)
    },
    enterListener(evt) {
      if (evt.code === 'Enter') {
        this.$emit('search', {
          id: this.attribute,
          active: true,
          prop: this.value
        })
      }
    },
    handleClearAll() {
      this.$refs.input.setFocus()
    },
    search() {
      const wanted = this.selectValue.length ? this.selectValue : this.value
      this.$emit('search', { id: this.attribute, active: true, prop: wanted })
    }
  }
}
</script>

<style lang="scss">
.r-search-input {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: center;
  width: 100%;

  &__icon {
    position: absolute;
    top: 0;
    left: 0.25rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-secondary !important;
  }

  &__wrapper.with-attrs {
    grid-template-columns: auto 1fr;
  }

  .r-select__input-wrapper {
    background: transparent !important;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 8px;
      right: 0;
      width: 2px;
      height: 24px;
      background: $dividers-low-contrast;
    }
  }

  .r-input__input {
    border-bottom: none !important;
  }

  .r-select__input {
    color: $text-primary !important;
    text-transform: uppercase;
  }

  &__input-select {
    .r-select__input {
      text-transform: none;
      font-weight: 400 !important;
    }

    .r-select__input-wrapper:after {
      display: none;
    }
  }

  .r-select__input-wrapper {
    padding-right: 2rem !important;
  }
}
</style>
