export const additionalLayerNames = [
  'fill_extrusion',
  'heatmap',
  'hexagon',
  'centroid',
  'polygon',
  'arrows',
  'icons',
  'stroke',
  'epure',
  'caption',
  'epure_extrusion'
]

export const clusterLayerNames = ['clusters', 'clusters_count']
