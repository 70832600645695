import $store from 'STORE'
import vue from 'vue'

export const checkUnsavedData = async ({ module, i18n }) => {
  const hasUnsavedData = $store.state[module].hasUnsavedData
  const mutationName = $store.state.storeRelations[module]

  if (
    hasUnsavedData &&
    !(await vue.$dialog({
      title: i18n.t('unsaved_data_will_be_lost') + '!',
      message: i18n.t('do_you_want_to_continue')
    }))
  ) {
    return true
  }

  $store.commit(mutationName, ['hasUnsavedData', false])

  return false
}
