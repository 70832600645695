<template>
  <portal to="main-portal">
    <transition name="fade">
      <div
        v-if="visible"
        id="overlay"
        class="dialog__overlay"
        @mousedown.stop="handleMouseDown"
        @mouseup.stop="handleMouseUp"
      >
        <transition name="zoom">
          <div
            id="dialog_window"
            class="dialog__wrapper keep-this-class-opened"
          >
            <r-title type="title-2">
              {{ content.title }}
            </r-title>
            <r-divider />
            <r-text>
              {{ content.message }}
            </r-text>
            <r-block
              class="dialog__list"
              scroll
              no-padding
            >
              <r-block
                v-for="({ text, ids }, i) in content.list"
                :key="i"
                col
                start
                no-padding
              >
                <r-text>
                  {{ text }}
                </r-text>

                <r-button
                  v-if="ids"
                  icon="copy"
                  :title="copyTitle"
                  mini
                  @click="copyIds(ids)"
                />
              </r-block>
            </r-block>
            <div
              class="dialog__controls"
              :class="{ toLeft }"
            >
              <r-button
                :icon="controls.no.icon || ''"
                type="secondary"
                simple
                @click="promiseState = false"
              >
                {{ controls.no.text }}
              </r-button>
              <r-button
                :icon="controls.yes.icon || ''"
                :type="controls.yes.type || 'primary'"
                :simple="controls.yes.type === 'simple'"
                mini
                @click="promiseState = true"
              >
                {{ controls.yes.text }}
              </r-button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </portal>
</template>

<script>
import i18n from '@/extensions/i18n'
import { sendNotify } from 'NOTIFICATION'

const dialogMessageConfig = {
  delete: {
    text: 'delete-message',
    icon: 'trash',
    type: 'danger'
  },
  cancel: { text: 'unsaved-changes-will-be-lost' },
  save: {
    text: 'save-all-changes',
    icon: 'sync-done',
    type: 'success'
  }
}

export default {
  data() {
    return {
      visible: false,
      content: null,
      controls: null,
      promiseInterval: null,
      promiseState: null,
      toLeft: false,
      closeOnClick: true,
      copyTitle: i18n.t('copy-list')
    }
  },
  methods: {
    copyIds(ids) {
      navigator.clipboard.writeText(ids)
      sendNotify('custom', null, {
        type: 'success',
        message: i18n.t('data_copied')
      })
    },
    async showDialog(context = {}) {
      document.addEventListener('keydown', this.keyHandler)

      const {
        title,
        message,
        controls,
        type,
        hideIds,
        closeOnClick = true
      } = context

      this.closeOnClick = closeOnClick
      const msg = message?.split('\n')
      const list = msg?.slice(1).map(text => {
        if (hideIds) {
          const arr = text.split(':')

          return { text: arr[0], ids: arr[1]?.trim() }
        }

        return { text }
      })
      console.log(msg)
      this.content = {
        title: i18n.t(title) || i18n.t('are-you-sure'),
        message: msg?.[0]
          ? i18n.t(msg?.[0])
          : dialogMessageConfig[type]
          ? i18n.t(dialogMessageConfig[type].text)
          : '',

        list
      }

      this.controls = controls || {
        yes: {
          text: i18n.t('yes'),
          icon: type ? i18n.t(dialogMessageConfig[type].icon) : '',
          type: type ? i18n.t(dialogMessageConfig[type].type) : ''
        },
        no: {
          text: i18n.t('no')
        }
      }

      this.visible = true

      return new Promise(res => {
        this.promiseInterval = setInterval(() => {
          if (this.promiseState !== null) {
            res(this.promiseState)
            this.resetDialog()
          }
        }, 50)
      })
    },
    handleMouseDown(e) {
      if (!this.closeOnClick) return

      if (e.target.id === 'overlay') {
        this.isOverlayMouseDown = true
      }
    },
    handleMouseUp(e) {
      if (
        this.closeOnClick &&
        e.target.id === 'overlay' &&
        this.isOverlayMouseDown
      ) {
        this.promiseState = false
      }

      this.isOverlayMouseDown = false
    },
    keyHandler(e) {
      if (!this.closeOnClick) return

      if (!this?.visible) return

      const keys = ['Enter', 'Escape']

      if (!keys.includes(e.code)) return

      e.stopPropagation()
      e.preventDefault()

      this.promiseState = e.code === 'Enter'
    },
    resetDialog() {
      document.removeEventListener('keydown', this.keyHandler)

      this.promiseState = null
      clearInterval(this.promiseInterval)
      this.promiseInterval = null
      this.content = null
      this.controls = null
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.dialog {
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000050;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
  }

  &__wrapper {
    background-color: $modal_bg;
    padding: 0.75rem;
    position: fixed;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: $border-radius;
    display: grid;
    grid-template-rows: auto auto auto 1fr auto;
    gap: 0.5rem;
    min-width: 250px;
    max-width: 350px;
    max-height: 80vh;
    overflow: hidden;
  }

  &__list {
    align-items: start !important;
  }

  &__controls {
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: end;
    justify-content: flex-end;

    // &.toLeft {
    // align-self: flex-start;
    // direction: rtl;
    // }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.33s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.33s;
  animation-fill-mode: both;
  animation-name: zoom;
}
.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
