export const structure = {
  map: {},
  // city: null,
  fields: {},
  objectCard: null,
  objectEditor: null,
  activeLayers: [],
  activeAdditionalLayers: [],
  hasUnsavedData: false,
  layerObjectList: null,
  layerNames: {},
  isSelectDisabled: false
}

export const nonClearableFields = ['map']
