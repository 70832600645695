import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from 'STORE'
import { MAIN_TITLE } from '@/config/constants'
import { getCookie, debounce } from 'HELPERS'
import i18n from '@/extensions/i18n'
import localforage from 'LF'

import management from './management'
import monitoring from './monitoring'
import explorer from './explorer'
import planning from './planning'
import forecast from './forecast'
import system from './system'

Vue.use(VueRouter)

const wrapper = () => import('@/views/wrapper/wrapper')

const objectToQueryString = obj => {
  const queryString = Object.keys(obj)
    .filter(k => !['from', 'modificationId'].some(ik => ik === k))
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')

  return `?${queryString}`
}

const setLastPath = debounce(async ({ to }) => {
  const url = to.path + objectToQueryString(to.query)
  await localforage.setItem('lastPath', url)
}, 64)

const beforeEnter = (to, from, next) => {
  if (!getCookie('ritmToken') && to.name !== 'login') {
    if (from.name !== 'login') {
      setLastPath({ to })
    }

    next({
      name: 'login'
    })
  } else if (to.name === 'explorer') {
    next()
  } else if (from.name !== to.name) {
    if (to.name !== 'login') {
      setLastPath({ to })
    }

    next()
  }
}

const routes = [
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/app/app'),
    beforeEnter,
    redirect: 'app/explorer',
    children: [
      explorer(beforeEnter),
      planning(beforeEnter, wrapper),
      forecast(beforeEnter, wrapper),
      monitoring(beforeEnter, wrapper),
      management(beforeEnter, wrapper),
      system(beforeEnter, wrapper)
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/user/auth/login'),
    beforeEnter,
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/print-page',
    name: 'print-page',
    component: () => import('@/modules/print-page/views'),
    beforeEnter,
    meta: {
      title: 'Страница печати'
    }
  },
  { name: 'all-routes', path: '*', redirect: '/app' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  setTimeout(() => {
    document.title = `${MAIN_TITLE} ${
      to.meta?.title
        ? `| ${i18n.t(to.meta.title)}`
        : to.meta?.subtitle
        ? `| ${i18n.t(to.meta.subtitle)}`
        : ''
    }`
  }, 128)

  const prevModule = from.fullPath.match(/^\/[^/]*\/[^/]*\/([^/?]*)/)?.[1]
  const currModule = to.fullPath.match(/^\/[^/]*\/[^/]*\/([^/?]*)/)?.[1]

  if (prevModule && prevModule !== currModule) {
    const mutation = $store.state.storeCleaners[prevModule]

    if (!mutation) return

    $store.commit(mutation)
  }

  const isAvailable =
    !!$store.state.availableRoutes && $store.state.availableRoutes.has(to.name)

  if (!isAvailable && to.name !== 'print-page') {
    router.push({
      name: 'explorer'
    })
  }
})

// Hack
const originalPush = router.push

router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router
