export const tranformTsyssetCodeToId = (data, related) => {
  return (data.tsysset || '')
    .split(',')
    .map(tsys => {
      return related?.tsysset.data.find(item => item.code === tsys)?.id
    })
    .filter(Boolean)
}

export const prepareObjInfoWithTsys = (objectInfo, related) => {
  if (!Array.isArray(objectInfo.tsysset)) return objectInfo

  if (objectInfo.tsysset.length === 1 && objectInfo.tsysset[0] === 'different')
    return objectInfo

  const tsysset = objectInfo.tsysset
    .map(tsys => {
      return related.tsysset.data.find(item => tsys === item.id).code
    })
    .toString()
  const result = {
    ...objectInfo,
    tsysset
  }

  return result
}
