import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getAsuddField: state => field => state[field],
    getAsuddCity: state => state.city,
    getAsuddComp: state => state.comp,
    getAsuddEndpoint: state => state.endpoint
  },
  mutations: {
    ASUDD_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    ASUDD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    },
    ASUDD_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    ASUDD_SPLICE(state, [field, value]) {
      const index = state[field].findIndex(i => i.id === value.id)

      if (index >= 0) state[field].splice(index, 1)
    },
    ASUDD_TOGGLE_ITEM(state, [field, value, subAttr]) {
      const index = state[field].findIndex(i => {
        if (subAttr) {
          return i.id === value.id && i[subAttr] === value[subAttr]
        }

        return i.id === value.id
      })

      if (index >= 0) state[field].splice(index, 1)
      else state[field].push(value)
    },
    ASUDD_UPDATE_ITEM(state, [field, item]) {
      const index = state[field].findIndex(i => i.id === item.id)

      if (index >= 0) {
        vue.set(state[field], index, item)
      }
    }
  }
}
