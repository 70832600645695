import $store from 'STORE'
import { sendNotify } from 'NOTIFICATION'
import i18n from '@/extensions/i18n'

export const handleServiceErrors = ({ res, layerId, moduleName, noNotify }) => {
  const { status, data } = res

  if (!Array.isArray(data?.Error) || !data?.Error?.length) {
    if (status === 200 || status === 201) {
      sendNotify('save')
    }

    return false
  }

  let message
  const locale = $store.getters.getLocale || 'ru'
  const fields = $store.state[moduleName].fields?.[layerId]
  const isDataUpdated = data.updated_tables?.includes(layerId)

  data.Error.forEach(({ type, fields: errorFields }) => {
    if (errorFields?.length) {
      const resFields = errorFields.reduce((acc, curr) => {
        const name = fields?.[curr]?.alias?.[locale] || curr

        if (!acc.includes(name)) acc.push(name)

        return acc
      }, [])

      const defaultMsg = `${i18n.t(`notify:${type}`)}: ${resFields.join(', ')}.`
      message = isDataUpdated
        ? `${defaultMsg} ${i18n.t('values_replaced')}.`
        : defaultMsg

      if (!noNotify) {
        $store.commit('SYSTEM_SET', [
          'notification',
          {
            type: 'error',
            title: i18n.t('notification'),
            message
          }
        ])
      }
    }
  })

  return message
}
